<template>
  <div>
    <!-- {{ customerAttachments[0].files[0] }} -->
    <b-card>
      <b-row class="pb-1">
        <b-col>
          <h3 class="pb-2">Anlagen</h3>
        </b-col>
        <b-col>
          <b-button
            v-b-modal.modal-attachment-select
            class="float-right"
            variant="primary"
          >
            <span class="text-nowrap">Anlage hinzufügen</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col
          v-for="attachmentItem in customerAttachments"
          :key="attachmentItem.created_at"
          class="ml-1"
        >
          <b-card no-body>
            <h5 class="">
              <!-- {{
                attachmentById(attachmentItem.id).attachment_type.attachmentType
              }} -->
              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" />
                </template>

                <b-dropdown-item @click="editAttachment(attachmentItem)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Bearbeiten</span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="deleteAttachmentWarning(attachmentItem)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Löschen</span>
                </b-dropdown-item>
              </b-dropdown>
            </h5>

            <viewer
              ref="viewer"
              :options="options"
              class="viewer"
              @inited="inited"
            >
              <b-media>
                <b-media-aside>
                  <div
                    v-for="attachmentImage in attachmentItem.files"
                    :key="attachmentImage.id"
                  >
                    <b-link
                      v-if="attachmentImage.mime == 'application/pdf'"
                      target="_blank"
                      :href="`${baseURL}${attachmentImage.url}`"
                    >
                      <b-img
                        src="@/assets/images/icons/file-icons/pdf.png"
                        rounded
                        center
                        fluid
                        height="80"
                        alt="Responsive image"
                      />
                      <p>{{ attachmentImage.name }}</p>
                    </b-link>
                  </div>

                  <div
                    v-for="attachmentImage in attachmentItem.files"
                    :key="`${baseURL}${attachmentImage.url}`"
                  >
                    <b-img
                      v-if="attachmentImage.mime != 'application/pdf'"
                      :src="`${baseURL}${attachmentImage.formats.thumbnail.url}`"
                      class="image"
                      :data-src="`${baseURL}${attachmentImage.url}`"
                    />
                  </div>
                </b-media-aside>

                <b-media-body>
                  <b-card-text>
                    {{ getFormattedDate(attachmentItem.created_at) }}
                  </b-card-text>
                  <b-card-text class="">
                    {{ attachmentItem.note }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </viewer>
          </b-card></b-col
        >
      </b-row>
      <b-modal
        id="modal-attachment-select"
        title="Anlagen"
        ok-title="Absenden"
        cancel-title="Abbrechen"
        centered
        cancel-variant="outline-secondary"
        @ok="dialogSubmitted"
      >
        <b-form
          ref="attachmentForm"
          @submit.prevent="onSubmit"
          @reset.prevent="onReset"
        >
          <b-form-group>
            <v-select
              v-model="attachment.attachment_type"
              placeholder="Anlagentyp auswählen..."
              label="attachmentType"
              :options="$store.getters.getAttachmentsTypes"
              :clearable="false"
            />
          </b-form-group>
          <b-form-group label="Notiz">
            <b-form-textarea
              id="textarea-auto-height"
              v-model="attachment.note"
              placeholder="Notiz hinzufügen..."
              rows="2"
              max-rows="2"
            />
          </b-form-group>
          <b-form-group label="Dateien">
            <b-form-file
              v-model="uploadFiles"
              placeholder="Dateien auswählen..."
              drop-placeholder="Drop file here..."
              multiple
            />
            <div class="mt-2">
              <div class="mb-1">Selected file:</div>
              <div v-for="(file, itemIndex) in uploadFiles" :key="itemIndex">
                {{ file.name }}
              </div>
            </div>
          </b-form-group>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import attachementHelper from '@/helper/attachementHelper'
import {
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardGroup,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BEmbed,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BLink,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
} from 'bootstrap-vue'
// import Helper from '@/helper/helper'
import helper from '@/helper/helper'
import API from '@/service/api'
import axios from 'axios'
import { component as Viewer } from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import vSelect from 'vue-select'

export default {
  setup() {
    const { attachementTitleForID } = attachementHelper()
    const { getFormattedDate } = helper()

    return { attachementTitleForID, getFormattedDate }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BButton,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    Viewer,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BFormFile,
    BCardGroup,
    BEmbed,
    BLink,
    axios,
    API,
  },
  directives: {},
  // mixins: [Helper],
  props: ['customerAttachments', 'customerID'],
  data() {
    return {
      attachment: {
        note: '',
        customer: this.customerID,
        attachment_type: '1',
        created_by: 'EK',
        updated_by: 'EK',
        files: [],
      },
      attachmentType: {},
      options: {
        url: 'data-src',
      },
      uploadFiles: [],
      file: null,
      baseURL: API().defaults.baseURL,
    }
  },
  methods: {
    deleteAttachmentWarning(data) {
      console.log(data)

      this.$swal({
        title: 'Bist du sicher?',
        // text: 'Das Lager wird unwiederruflich gelöscht.',
        text: 'Anlagen wirklich löschen?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, löschen!',
        cancelButtonText: 'Abbrechen',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('deleteAttachment', data.id)
          this.$router.go()
        }
      })
    },

    attachmentById(id) {
      return this.$store.getters.attachmentById(id)
    },
    inited(viewer) {
      this.$viewer = viewer
    },
    show() {
      this.$viewer.show()
    },
    handleFileUpload() {
      // eslint-disable-next-line prefer-destructuring
      this.file = this.$refs.file.files[0]
      console.log(this.file)
    },

    dialogSubmitted() {
      // alert('Dialog OK')

      const formData = new FormData()

      for (let i = 0; i < this.uploadFiles.length; i += 1) {
        const file = this.uploadFiles[i]

        formData.append('files', file)
      }

      const config = {
        onUploadProgress: uploadEvent => {
          console.log(
            `Upload Progress: ${Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            )} %`
          )
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      this.$store
        .dispatch('addFile', formData, config)
        .then(result => {
          this.attachment.files = result.map(a => a.id)

          // this.attachment.files.push(file.id)

          this.$store.dispatch('addAttachment', this.attachment).then(() => {
            this.$router.go()
          })
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss">
.image {
  height: 120px;
  cursor: pointer;
  margin-right: 5px;
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
