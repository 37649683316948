import { ref } from '@vue/composition-api'

 export default function attachementHelper() {
    function attachementTitleForID(attachementID) {
      switch (attachementID) {
        case 'personal_id':
          return ref('Personalausweis').value

        case 'contract':
          return ref('Mietvertrag').value

        case 'sepa':
          return ref('SEPA').value

        case 'trade_license':
          return ref('Gewerbeschein').value

        default:
          return ref('Dokument').value
      }
    }

    return {
      attachementTitleForID,
    }
 }
