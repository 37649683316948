<template>
  <b-card class="card-congratulation-medal">
    <h3>Einnahmen</h3>

    <b-card-body>
      <!-- User Stats -->
      <div class="d-flex align-items-center">
        <feather-icon icon="DollarSignIcon" size="38" />
        <div class="d-flex align-items-center mr-4">
          <div class="ml-1">
            <h2 class="mb-0">
              {{
                this.$store.getters.orderItemsTotalSumFromStartToNow.toLocaleString(
                  'de-CO'
                )
              }}
              €
            </h2>
            <small>Einnahmen Gesamt</small>
          </div>
        </div>
        <div class="d-flex align-items-center mr-4">
          <feather-icon icon="TrendingUpIcon" size="38" />
          <div class="ml-1">
            <h2 class="mb-0">0,00 €</h2>
            <small>Monatliche Einnahmen</small>
          </div>
        </div>
        <div class="d-flex align-items-center mr-4">
          <feather-icon icon="BoxIcon" size="38" />
          <div class="ml-1">
            <h2 class="mb-0">
              {{ activeStorageCount() }}
            </h2>
            <small>Aktive Lager</small>
          </div>
        </div>
        <div class="d-flex align-items-center mr-4">
          <feather-icon icon="ShoppingCartIcon" size="38" />
          <div class="ml-1">
            <h2 class="mb-0">
              {{ ordersFromCustomer.length }}
            </h2>
            <small>Buchungen</small>
          </div>
        </div>
      </div>

      <!-- <div>
        <div class="d-flex align-items-center pt-2">
          <feather-icon icon="TrendingUpIcon" size="28" />
          <div class="ml-1">
            <h4 class="mb-0">
              $99.87k
            </h4>
            <small>Einnahmen Gesamt</small>
          </div>
        </div>
      </div> -->
    </b-card-body>
  </b-card>
</template>

<script>
import DashboardTiles from '@/views/dashboard/DashboardTiles'
import {
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BButton,
  BImg,
} from 'bootstrap-vue'

export default {
  setup() {
    // eslint-disable-next-line operator-linebreak
    const { getCountOfAllStorages, getFreeStorages, getRentedStorages } =
      DashboardTiles()

    return {
      getCountOfAllStorages,
      getFreeStorages,
      getRentedStorages,
    }
  },
  directives: {},
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BImg,
  },
  props: {
    ordersFromCustomer: {
      type: Array,
      required: true,
    },
  },
  computed: {
    getTotalSum() {
      let sum = 0
      for (let index = 0; index < this.ordersFromCustomer.length; index += 1) {
        sum += this.ordersFromCustomer[index].order_items.reduce(
          (acc, currentValue) => acc + currentValue.price,
          0
        )
      }
      return sum
    },
  },
  methods: {
    activeStorageCount() {
      let count = 0

      for (let index = 0; index < this.ordersFromCustomer.length; index += 1) {
        // eslint-disable-next-line no-loop-func
        this.ordersFromCustomer[index].order_items.forEach(orderItem => {
          if (Date.now() < new Date(orderItem.rentEnd).getTime()) {
            count += 1
          }
        })
      }
      return count
    },
  },
}
</script>

<style></style>
