<template>
  <b-card>
    <b-row>
      <b-col>
        <table>
          <tr>
            <div class="mb-1">
              <h5>Bezahlinformationen</h5>
            </div>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UsersIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Bezahlart</span>
            </th>
            <td class="pb-50">
              Lastschrift
            </td>
          </tr>
          <tr>
            <th class="pt-1 pb-50">
              <feather-icon
                icon="MapIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Bank</span>
            </th>
            <td class="pt-1 pb-50 text-capitalize">
              Volksbank Paderborn
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">IBAN</span>
            </th>
            <td class="pb-50 text-capitalize">
              DE90123456781234567890
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">BIC</span>
            </th>
            <td class="pb-50">
              DGPBDE3MXXX
            </td>
          </tr>
          <tr>
            <th class="pt-1 pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Kreditkarte</span>
            </th>
            <td class="pt-1 pb-50">
              Mastercard
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="SmartphoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">KD-Nummer</span>
            </th>
            <td class="pb-50">
              1234567890
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {},
  props: {
    customerEntry: {
      type: Object,
      required: false,
    },
  },
  methods: {
    // warning
    deleteWarning() {
      this.$swal({
        title: 'Bist du sicher?',
        text:
          'Alle Informationen zu dem Kunden (Kundendetails, Bestellungen, Einnahmen, Aktivitäten, etc.) werden unwiederruflich gelöscht.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, löschen!',
        cancelButtonText: 'Abbrechen',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

hr.style1 {
  border-top: 1px solid #8c8b8b;
}
hr.style5 {
  background-color: #fff;
  border-top: 2px dashed #8c8b8b;
}
</style>
