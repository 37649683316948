import storageHelper from '@/helper/storageHelper'

export default function DashboardTiles() {
  // User ########################################################
  // get current user
  function currentUser() {
    return this.$store.getters.user
  }

  // Inquiries ########################################################
  // Get count of all inquiries
  function getCountOfAllInquiries() {
    return this.$store.getters.inquiriesAllEntries.length
  }

  // Get accepted inquiries
  function getAcceptedInquiries() {
    return this.$store.getters.inquiriesAllAcceptedEntries.length
  }

  // Get all open inquiries
  function getOpenInquiries() {
    return this.$store.getters.inquiriesAllOpenEntries.length
  }

  // Offers ########################################################

  // Get count of all offers
  function getCountOfAllOffers() {
    return this.$store.getters.offerEntries.length
  }

  // Get total sum of all offers
  function getTotalSumOfAllStoragesAndOffers() {
    return `${this.$store.getters.getTotalSumOfAllStoragesAndOffers.toLocaleString(
      'de-CO'
    )} €`
  }

  // Get all offers where isActive = true
  function getActiveOffers() {
    return this.$store.getters.getActiveOffers.length
  }

  // Storages ########################################################

  // Get count of all storages
  function getCountOfAllStorages() {
    return this.$store.getters.storageEntries.length
  }

  // Get free storages
  function getFreeStorages() {
    return storageHelper().countOfNotRentedStorages().value
  }

  // Get rented storages
  function getRentedStorages() {
    return storageHelper().countOfRentedStorages().value
  }

  // Order ########################################################

  // Get count of all orders
  function getCountOfAllOrders() {
    return this.$store.getters.orderEntries.length
  }

  // // Get sum of all order items
  // function getSumOfAllActiveOrderItems() {
  //     return this.$store.getters.orderItemsTotalSum
  // }

  // Order Items ########################################################

  // Get count of all order items
  function getCountOfAllOrderItems() {
    return this.$store.getters.orderItemsEntries.length
  }

  // Get total sum of all order items wich dont have a moveoutdate for the current month
  function getSumOfAllActiveOrderItemsForCurrentMonth() {
    return `${this.$store.getters.orderItemsTotalSumForCurrentMonth.toLocaleString(
      'de-CO'
    )} €`
  }

  // Get sum of all order items
  function getSumOfAllActiveOrderItems() {
    return `${this.$store.getters.orderItemsTotalSum.toLocaleString('de-CO')} €`
  }

  // Get sum of all order items till today included moveoutdate
  function getSumOfAllActiveOrderItemsTillToday() {
    return `${this.$store.getters.orderItemsTotalSumFromStartToNow.toLocaleString(
      'de-CO'
    )} €`
  }

  // Get sum of all order items till moveoutdate
  function getSumOfAllActiveOrderItemsTillMoveoutdate() {
    return `${this.$store.getters.orderItemsTotalSumFromStartToMoveOutDate.toLocaleString(
      'de-CO'
    )} €`
  }

  // Get the an array with the monthly revenue form the last 12 months.
  function getOrderItemsMonthlyRevenue() {
    return this.$store.getters.orderItemsMonthlyRevenue
  }

  // Get revenue by substraction of all order items till moveoutdate and all order items till today
  function getRevenueTillNow() {
    // eslint-disable-next-line operator-linebreak
    const totalSumFromStartToNow =
      this.$store.getters.orderItemsTotalSumFromStartToNow
    // eslint-disable-next-line operator-linebreak
    const totalSumFromStartToMoveOutDate =
      this.$store.getters.orderItemsTotalSumFromStartToMoveOutDate

    const revenue = totalSumFromStartToNow - totalSumFromStartToMoveOutDate
    return `${revenue.toLocaleString('de-CO', { maximumFractionDigits: 0 })} €`
  }

  return {
    currentUser,
    getCountOfAllStorages,
    getFreeStorages,
    getRentedStorages,
    getCountOfAllInquiries,
    getAcceptedInquiries,
    getOpenInquiries,
    getCountOfAllOffers,
    getActiveOffers,
    getTotalSumOfAllStoragesAndOffers,
    getCountOfAllOrders,
    getCountOfAllOrderItems,
    getSumOfAllActiveOrderItems,
    getSumOfAllActiveOrderItemsTillToday,
    getSumOfAllActiveOrderItemsTillMoveoutdate,
    getRevenueTillNow,
    getOrderItemsMonthlyRevenue,
    getSumOfAllActiveOrderItemsForCurrentMonth,
  }
}
