<template>
  <b-card>
    <div class="mb-1">
      <h5>Rechnungsanschrift</h5>
    </div>
    <table class="w-100">
      <tr>
        <th class="pb-50">
          <feather-icon icon="MapIcon" class="mr-75" />
          <span class="font-weight-bold">Strasse</span>
        </th>
        <td class="pb-50">
          {{ customerEntry.billingStreet }}
          {{ customerEntry.billingStreetNumber }}
        </td>
      </tr>
      <tr>
        <th class="pb-50">
          <feather-icon icon="MapPinIcon" class="mr-75" />
          <span class="font-weight-bold">PLZ | Stadt</span>
        </th>
        <td class="pb-50">
          {{ customerEntry.billingZipCode }} {{ customerEntry.billingCity }}
        </td>
      </tr>
      <tr>
        <th class="pb-50">
          <feather-icon icon="FlagIcon" class="mr-75" />
          <span class="font-weight-bold">Land</span>
        </th>
        <td class="pb-50">
          {{ customerEntry.billingCountry }}
        </td>
      </tr>
    </table>

    <div class="mt-1 mb-1">
      <h5>Bezahlinformationen</h5>
    </div>
    <table class="w-100">
      <tr>
        <th class="pb-50">
          <feather-icon icon="UsersIcon" class="mr-75" />
          <span class="font-weight-bold">Bezahlart</span>
        </th>
        <td class="pb-50">-</td>
      </tr>
      <tr>
        <th class="pt-1 pb-50">
          <feather-icon icon="MapIcon" class="mr-75" />
          <span class="font-weight-bold">Bank</span>
        </th>
        <td class="pt-1 pb-50 text-capitalize">
          <p style="word-wrap: break-word">
            {{ customerEntry.bankName }}
          </p>
        </td>
      </tr>
      <tr>
        <th class="pb-50">
          <feather-icon icon="MapPinIcon" class="mr-75" />
          <span class="font-weight-bold">IBAN</span>
        </th>
        <td class="pb-50 text-capitalize">
          {{ customerEntry.bankIBAN }}
        </td>
      </tr>
      <tr>
        <th class="pb-50">
          <feather-icon icon="FlagIcon" class="mr-75" />
          <span class="font-weight-bold">BIC</span>
        </th>
        <td class="pb-50 float-left">
          {{ customerEntry.bankBIC }}
        </td>
      </tr>
      <tr>
        <th class="pt-1 pb-50">
          <feather-icon icon="PhoneIcon" class="mr-75" />
          <span class="font-weight-bold">Kreditkarte</span>
        </th>
        <td class="pt-1 pb-50">-</td>
      </tr>
    </table>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {},
  props: {
    customerEntry: {
      type: Object,
      required: false,
    },
  },
  methods: {
    // warning
    deleteWarning() {
      this.$swal({
        title: 'Bist du sicher?',
        text: 'Alle Informationen zu dem Kunden (Kundendetails, Buchungen, Einnahmen, Aktivitäten, etc.) werden unwiederruflich gelöscht.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, löschen!',
        cancelButtonText: 'Abbrechen',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

hr.style1 {
  border-top: 1px solid #8c8b8b;
}
hr.style5 {
  background-color: #fff;
  border-top: 2px dashed #8c8b8b;
}
</style>
