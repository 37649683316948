<template lang="">
  <div>
    <!-- {{ customerEntry }} -->
    <template>
      <!-- First Row -->
      <b-row class="match-height">
        <b-col xl="8" lg="12" md="12" sm="12">
          <b-row class="match-height">
            <b-col xl="6" lg="6" md="12" sm="12">
              <customer-view-info-card-part-one
                :customer-entry="customerEntry"
              />
            </b-col>
            <b-col xl="6" lg="6" md="12" sm="12">
              <customer-view-info-card-part-two
                :customer-entry="customerEntry"
              />
            </b-col>

            <b-col xl="12" lg="12" md="12" sm="12">
              <customer-view-plan-card
                v-if="ordersFromCustomer && ordersFromCustomer.length > 0"
                :orders-from-customer="ordersFromCustomer"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="4" lg="12" md="12" sm="12">
          <customer-timeline
            :id="this.id"
            :customer-activities="customerEntry"
          />
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col cols="12" xl="12" lg="12" md="12">
          <customer-orders
            v-if="ordersFromCustomer && ordersFromCustomer.length > 0"
            :orders="ordersFromCustomer"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col xl="12" lg="12" md="12" sm="12">
          <customer-attachements
            :customer-attachments="customerEntry.attachments"
            :customer-i-d="customerEntry.id"
          />
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <customer-timeline />
        </b-col>
      </b-row> -->
    </template>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import CustomerViewInfoCardPartOne from './CustomerViewInfoCardPartOne.vue'
import CustomerViewInfoCardPartTwo from './CustomerViewInfoCardPartTwo.vue'
import CustomerViewInfoCardPartThree from './CustomerViewInfoCardPartThree.vue'
import CustomerViewPlanCard from './CustomerViewPlanCard.vue'
import CustomerOrders from './CustomerOrders.vue'
import CustomerTimeline from './CustomerTimeline.vue'
import CustomerAttachements from './CustomerAttachements.vue'

export default {
  components: {
    BRow,
    BCol,
    // Local Components
    CustomerViewInfoCardPartOne,
    CustomerViewInfoCardPartTwo,
    CustomerViewInfoCardPartThree,
    CustomerViewPlanCard,
    CustomerOrders,
    CustomerTimeline,
    CustomerAttachements,
  },
  props: ['id'],
  computed: {
    customerEntry() {
      const customer = this.$store.getters.customerById(Number(this.id))
      if (!customer) {
        // Fehlerbehandlung, z.B. Benutzer darüber informieren, dass der Kunde nicht gefunden wurde
        return null
      }
      return customer
    },
    ordersFromCustomer() {
      const order = this.$store.getters.orderByCustomerId(Number(this.id))

      return order
    },
  },
  mounted() {
    // this.$store.dispatch('getCustomerEntries')
  },
  methods: {
    sortedAttachments() {
      // eslint-disable-next-line no-confusing-arrow
      return this.customerEntry.attachments.sort(
        (a, b) => (a.created_at < b.created_at ? 1 : -1)
        // eslint-disable-next-line function-paren-newline
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
