<template>
  <b-card>
    <!-- {{ activitiesEntries[0].customer.id }} -->
    <!-- {{ customerEntry.activities }} -->
    <div>
      <b-row class="pb-1">
        <b-col>
          <h3 class="pb-2">Aktivitäten</h3>
        </b-col>
        <!-- <b-col>
          <b-button class="btn-icon" variant="primary" @click="reloadTimeline">
            <feather-icon icon="RotateCwIcon" />
          </b-button>
        </b-col> -->
        <b-col>
          <b-button @click="addActivity" class="float-right" variant="primary">
            <span class="text-nowrap">Aktivität hinzufügen</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div style="height: 455px; overflow-y: scroll">
            <app-timeline :key="componentKey">
              <app-timeline-item
                v-for="(item, itemIndex) in updatedCustomerActivities"
                :key="itemIndex"
                variant="success"
              >
                <div
                  class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                >
                  <h6>{{ item.title }}</h6>
                  <small class="text-muted pr-1">{{
                    getFormattedDate(item.created_at)
                  }}</small>
                </div>
                <p>{{ item.notes }}</p>
                <p>
                  <b-badge pill variant="light-primary">
                    {{ activitiesById(item.id).activity_type.activityType }}
                  </b-badge>
                  <b-button
                    size="sm"
                    class="ml-1 mr-1"
                    variant="warning"
                    @click="editActivity(item)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    size="sm"
                    variant="danger"
                    @click="deleteActivity(item.id)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </p>
              </app-timeline-item>
            </app-timeline>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal
      id="modal-timeline-select"
      title="Aktivität"
      ok-title="Absenden"
      cancel-title="Abbrechen"
      centered
      cancel-variant="outline-secondary"
      @ok="dialogSubmitted"
    >
      <b-form
        ref="activityform"
        @submit.prevent="onSubmit"
        @reset.prevent="onReset"
      >
        <b-form-group>
          <v-select
            v-model="activity.activity_type"
            :options="activityTypes"
            :reduce="activityType => activityType.id"
            label="activityType"
            placeholder="Aktivitätstyp auswählen..."
            :clearable="false"
          />
        </b-form-group>
        <b-form-group label="Titel">
          <b-form-input v-model="activity.title" placeholder="" />
        </b-form-group>
        <b-form-group label="Notiz">
          <b-form-textarea
            id="textarea-auto-height"
            v-model="activity.notes"
            placeholder=""
            rows="4"
            max-rows="10"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import helper from '@/helper/helper'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue'

export default {
  setup() {
    const { getFormattedDate } = helper()
    return { getFormattedDate }
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BFormTextarea,
    ToastificationContent,
  },
  props: ['customerActivities', 'id'],
  data() {
    return {
      activity: this.getInitialActivity(),
      selectedActivityType: '',
      componentKey: 0,
    }
  },
  computed: {
    customerEntry() {
      return this.$store.getters.customerById(Number(this.id))
    },
    activitiesEntries() {
      return this.$store.getters.activitiesEntries
    },
    activityTypes() {
      return this.$store.getters.getActivityTypes
    },
    updatedCustomerActivities() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.customerEntry.activities.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      )
    },
  },

  methods: {
    getInitialActivity() {
      return {
        notes: '',
        customer: this.id,
        title: '',
        activity_type: '',
      }
    },
    resetActivity() {
      this.activity = this.getInitialActivity()
    },
    reloadTimeline() {
      this.$store.dispatch('getCustomerEntries')
    },
    activitiesById(id) {
      return this.$store.getters.activitiesById(id)
    },
    dialogSubmitted() {
      if (this.activity.id) {
        this.$store
          .dispatch('editActivity', this.activity)
          .then(() => {
            console.log('Activity edited')
            this.$store.dispatch('getCustomerEntries').then(() => {
              this.$bvModal.hide('modal-timeline-select')
            })
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.$store
          .dispatch('addActivity', this.activity)
          .then(() => {
            console.log('Activity added')
            this.$store.dispatch('getCustomerEntries').then(() => {
              this.$router.go()
            })
          })
          .catch(error => {
            console.log(error)
          })
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.activity.title,
          text: this.activity.activity_type,
          icon: 'EditIcon',
          variant: 'success',
        },
      })
      this.resetActivity()
    },
    editActivity(item) {
      this.activity = { ...item }
      const activityType = this.activityTypes.find(
        type => type.id === item.activity_type.id
      )
      this.activity.activity_type = activityType || item.activity_type
      this.$bvModal.show('modal-timeline-select')
    },
    deleteActivity(id) {
      this.$swal({
        title: 'Aktivität wirklich löschen?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, löschen!',
        cancelButtonText: 'Abbrechen',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('deleteActivity', id).then(() => {
            this.$store.dispatch('getCustomerEntries').then(() => {
              this.$router.go()
            })
          })
        }
      })
    },
    addActivity() {
      this.resetActivity()
      this.$bvModal.show('modal-timeline-select')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
