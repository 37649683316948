<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col>
        <!-- Name & Email -->

        <div>
          <div>
            <span>{{ customerEntry.salutation }}</span>
            <h3>{{ customerEntry.firstName }} {{ customerEntry.lastName }}</h3>
            <span class="card-text"
              >Kundenummer: <strong>{{ customerEntry.id }}</strong>
              {{ customerEntry.email }}</span
            >
          </div>
        </div>
      </b-col>
      <b-col>
        <!-- Edit & Delete Button -->
        <!-- <div class="d-flex float-right">
          <b-button
            variant="secondary"
            class="float-right"
          >
            <feather-icon icon="Edit2Icon" />
          </b-button>

          <b-button
            variant="danger"
            class="ml-1"
            @click="deleteWarning"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </div> -->
      </b-col>
    </b-row>
    <hr class="style1" />
    <b-row>
      <!-- Right Col: Table -->
      <b-col>
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UsersIcon" class="mr-75" />
              <span class="font-weight-bold">Organization</span>
            </th>
            <td class="pb-50">
              {{ customerEntry.organizationName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="MapIcon" class="mr-75" />
              <span class="font-weight-bold">Strasse</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ customerEntry.street }} {{ customerEntry.streetNumber }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="MapPinIcon" class="mr-75" />
              <span class="font-weight-bold">PLZ | Stadt</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ customerEntry.zipCode }} {{ customerEntry.city }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">Land</span>
            </th>
            <td class="pb-50">
              {{ customerEntry.country }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Telefon</span>
            </th>
            <td>
              {{ customerEntry.phoneNumber }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="SmartphoneIcon" class="mr-75" />
              <span class="font-weight-bold">Mobile</span>
            </th>
            <td>
              {{ customerEntry.mobileNumber }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PrinterIcon" class="mr-75" />
              <span class="font-weight-bold">Fax</span>
            </th>
            <td>
              {{ customerEntry.faxNumber }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {},
  props: {
    customerEntry: {
      type: Object,
      required: false,
    },
  },
  methods: {
    // warning
    deleteWarning() {
      this.$swal({
        title: 'Bist du sicher?',
        text: 'Alle Informationen zu dem Kunden (Kundendetails, Buchungen, Einnahmen, Aktivitäten, etc.) werden unwiederruflich gelöscht.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, löschen!',
        cancelButtonText: 'Abbrechen',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

hr.style1 {
  border-top: 1px solid #8c8b8b;
}
hr.style5 {
  background-color: #fff;
  border-top: 2px dashed #8c8b8b;
}
</style>
